<template>
  <div class="contain" style="margin:0">
    <div class="modalityTop">
      <el-form
        ref="search"
        class="searchForm"
        :model="paramsData"
        label-width="auto"
      >
        <el-form-item label="时间段" prop="equipmentStatus" label-width="54px">
          <!-- @change="search" -->
          <el-date-picker
            
            size="mini"
            style="width:250px"
            v-model="DataPicker"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="巡检区域" prop="equipmentStatus" style="margin-left: 14px;">
          <el-select
            clearable
            size="mini"
            style="width:170px"
            v-model="paramsData.networkId"
            placeholder="请选择巡检区域"
            
          >
          <!-- @change="search" -->
            <el-option
              v-for="item in selectDownArray"
              :key="item.id"
                   :label="item.networkName"
            :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="巡线员" prop="equipmentStatus" >
          <el-select
            clearable
            size="mini"
            style="width:170px"
            v-model="paramsData.userId"
            placeholder="请选择巡线员"
            
          >
          <!-- @change="search" -->
            <el-option
              v-for="item in excutorDownArray"
              :key="item.id"
              :label="item.username"
              :value="item.id">
            
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="任务状态" prop="equipmentStatus" style="margin-left: 14px;">
          <el-select
            clearable
            size="mini"
            style="width:170px"
            v-model="paramsData.taskGenerateStatus"
            placeholder="请选择状态"
            
          >
          <!-- @change="search" -->
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="关键字" prop="equipmentStatus">
          <el-input
          clearable
          size="mini"
            placeholder="请输入任务编号"
            v-model="paramsData.searchText"
            style="width:250px"
          >
          </el-input>
        </el-form-item>
       <el-form-item label="" prop="" label-width="0">
        <el-button
          size="mini"
          type="primary"
          @click="search"
          style="margin-left:10px;"
          >查询</el-button
        >
        <el-button
          size="mini"
          type="warning"
          style="margin-left: 10px;"
          @click="resize"
          >重置</el-button
        ><el-button
          size="mini"
          type="primary"
          @click="exportDetil"
          v-if="$anthButtons.getButtonAuth('deriveLinetask')"
          >导出</el-button
        >
         </el-form-item>
      </el-form>
    </div>
    <div class="modalityCenter">
     <!-- <el-button
          size="mini"
          type="primary"
          class="addButton"
          @click="addGroup"
          >添加</el-button> -->
    <el-table
     border
      :data="tableData"
      style="width: 100%"
      height="calc(100vh - 335px)"
      @selection-change="handleSelectionChange"
      :row-class-name="tableRowClassName"
      :header-cell-style="{
        color: '#333',
        fontFamily: 'MicrosoftYaHeiUI',
        fontSize: '14px',
        fontWeight: 900,
        textAlign:'center',
        background: '#f8f8f9',
      }"
    >
     <el-table-column type="index" align="left" label="序号" width="80"></el-table-column>
      <el-table-column property="taskCode" label="任务编号" align="left" show-overflow-tooltip>
        <template slot-scope="{row}">
          <div :style="{color:row.isRead==2?'red':'#0C235B'}">{{row.taskCode}}</div>
        </template>
      </el-table-column>

      <!-- <el-table-column property="taskName"
        label="任务名称"
        align="left"
        show-overflow-tooltip
      ></el-table-column> -->
      <el-table-column
        property="networkName"
        label="巡检区域"
        align="left"
      ></el-table-column>
      <!-- <el-table-column
        property="lineLength"
        label="管线长度（km）"
        align="left"
      >
      <template slot-scope="scope">
          {{ (scope.row.lineLength / 1000).toFixed(3) }}
        </template>
      </el-table-column> -->
      <el-table-column align="center" prop="lineLength" label="管线长度（km）">
       <template slot-scope="{row}">
          <div class="examine" style="color:#2C9EF7;cursor: pointer"  @click="seeKm(row)">{{(row.jsL&&row.jsL!=-1)?row.jsL: (row.jsL == 0 ? 0 :'查看')}}</div>
        </template>
      </el-table-column>
      <!-- <el-table-column
        property="checkingPoint"
        label="巡检点个数"
        align="left"
      ></el-table-column> -->
      <el-table-column
        property="punch"
        label="打卡点数"
        width="80"
        align="left"
      ></el-table-column>
      <el-table-column
        property="taskFrequency"
        label="巡检周期"
        align="left"
        width="80"
      >
      <template slot-scope="{ row }">
            {{row.dayNumber || 1}}天{{ row.frequency }}次
          </template>
      </el-table-column>
      <el-table-column
        property="taskFrequency"
        label=''
        align="left"
        width="120"
      >
      <template slot="header">
          <div>巡线轨迹</div>
          <div>(完成数/提交数)</div>
        </template>
      <template slot-scope="{ row }">
        <div @click="SeetracFun(row)" v-if="row.trackSum!=0" style="color:#84d7ff;cursor: pointer">
            {{ row.trackFinish }}/{{row.trackSum || 1}}
        </div> 
        <div v-else>0</div> 
        </template>
      </el-table-column>
      <el-table-column
        property="executorPeopleName"
        label="巡线员"
        align="left"
      ></el-table-column>
      
      <el-table-column property="executionTime" width="185" label="执行时间" align="left">
        <template slot-scope="{ row }">
          {{
            row.isRedeploy == "3"
              ? ''
              : `${String(row.taskDurationStartTime).replaceAll("-", "/")} - ${String(row.taskDurationEndTime).replaceAll("-", "/")}`
          }}
        </template>
      </el-table-column>
      <!-- <el-table-column property="createTime" label="创建时间" align="left">

      </el-table-column> -->
      <el-table-column
        property="taskStatusName"
        label="任务状态"
        width="90"
        align="center">
        <template slot-scope="{ row }">
          <!-- 未开始 部分转派 -->
          <!-- isRedeploy:1：未转派，2.部分转派，3.全部转派
          taskStatusName：if(start == '进行中') return 'warning' 
      if(start == '已转派') return '' 
      if(start == '已完成') return 'success' 
      if(start == '未完成') return 'danger' 
      if(start == '未开始') return 'danger' -->
          <el-tag v-if="row.taskStatusName == '未开始' && row.isRedeploy != 3" size="mini" style="color: #F4C41D;background: #FFFCF6;border: 1px solid #F0DCA8;">
              {{ row.isRedeploy == 3?'已转派':row.taskStatusName }}
          </el-tag> 
          <el-tag v-else-if="row.taskStatusName == '已完成'" size="mini" type='success'>
              {{ row.taskStatusName }}
          </el-tag>
          <el-tag v-else-if="row.isRedeploy == 3" size="mini" >
              {{ row.isRedeploy == 3?'已转派':row.taskStatusName }}
          </el-tag> 
           <el-tag v-else :type='taskStatus(row.taskStatusName)' size="mini">
              {{ row.isRedeploy == 3?'已转派':row.taskStatusName }}
          </el-tag>  


          <!--未开始 全部转派 -->
          <!-- <el-tag v-else-if="(row.taskStatusName == '未开始' && row.isRedeploy == 3)"  size="mini">
              {{ row.isRedeploy == 3?'已转派':row.taskStatusName }}
          </el-tag>   -->
          <!-- 未完成 全部转派 -->
          <!-- <el-tag v-else-if="(row.taskStatusName == '未完成' && row.isRedeploy == 3)"  size="mini">
              {{ row.isRedeploy == 3?'已转派':row.taskStatusName }}
          </el-tag>   -->
          <!-- 已完成 全部转派 -->
          <!-- <el-tag v-else-if="(row.isRedeploy != 1 )"  size="mini">
              {{ row.isRedeploy != 3?'已转派':row.taskStatusName }}
          </el-tag>  -->
          <!-- 进行中 全部转派 -->
          <!-- <el-tag v-else-if="(row.taskStatusName == '进行中' && row.isRedeploy == 3)"  size="mini" stype='success'>
              已完成
          </el-tag>  -->
          <!-- 其他状态 -->
          <!-- <el-tag v-else :type='taskStatus(row.taskStatusName)' size="mini">
              {{ row.isRedeploy == 3?'已转派':row.taskStatusName }}
          </el-tag>   -->

        </template>
      </el-table-column>
      <el-table-column
        property="taskRemark"
        label="备注"
        align="left">
      </el-table-column>
      <el-table-column label="操作" align="left" width="160">
        <template slot-scope="{ row }">
          <el-button
            type="primary"
            size="mini"
            v-if="$anthButtons.getButtonAuth('remarkLinetask')"
            @click="IsOpenFun(4, row)"
            >备注</el-button
          >
          <el-button type="primary" plain  
          v-if="(row.isRedeploy != 3 && row.taskStatusName != '未完成' && row.taskStatusName != '已完成') && $anthButtons.getButtonAuth('turnLinetask')"
           @click="handle(row)" size="mini"
            >转派</el-button
          >
          <!-- <el-button
            type="text"
            @click="del(row)"
            size="small"
            style="color:#FF5400"
            v-if="row.taskGenerateStatus == 0"
            >删除</el-button
          > -->
        </template>
      </el-table-column>
    </el-table>

    <div style="text-align: left">
      <el-pagination
        @size-change="handleSizeChange"
        style="margin-top: 10px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        :page-sizes="[10,30, 50, 100]"
        :page-size="paramsData.size"
        :current-page="paramsData.current"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    </div>

  
    <!-- 转派 -->
    <el-dialog :title="titles" :visible.sync="redeployVisible" @closed="CloseChangePlan" width="460px" :close-on-click-modal='false'>
      <el-form :model="DeviceData"  ref="DeviceData" label-width="80px" :rules="rules">
        <el-form-item label="巡线员" prop="taskUser">
          <el-select style="width: 338px" v-model="DeviceData.taskUser">
            <el-option
              v-for="item in excutorSpecialArray"
              :key="item.id"
              :label="item.username+'('+item.mobile+')'"
              :value="item.id.toString()">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="执行时间" prop="taskDurationStartTime">
          <el-date-picker style="display:inline-block;width: 162px;" value-format="yyyy-MM-dd" 
          v-model="DeviceData.taskDurationStartTime"  type="date" placeholder="开始日期"></el-date-picker>
          -
          <el-date-picker style="display:inline-block;width: 162px;" readonly v-model="DeviceData.taskDurationEndTime" type="date" placeholder="结束日期"></el-date-picker>
        
        </el-form-item>
        <el-form-item label="备注" prop="taskRemark">
          <el-input v-model="DeviceData.taskRemark" 
            type="textarea" 
            style="width: 338px" 
            placeholder="请填写备注" 
            maxlength="200"
            show-word-limit>
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="redeployVisible = false" >取 消</el-button>
        <el-button size="small" @click="submitRedeploy" type="primary">确 认</el-button>
      </div>
    </el-dialog>
    <!-- 备注 -->
    <el-dialog :visible.sync="remarkBool" width="460px" :close-on-click-modal='false'>
       <el-form :model="remarkData"  ref="remarkData" label-width="80px" :rules="rules1">
        <el-form-item label="备注" prop="taskRemark" label-width="64px">
          <el-input
            style="width: 100%"
            maxlength="200"
            name="limitLength"
            type="textarea"
            show-word-limit
            placeholder="请输入备注"
            v-model="remarkData.taskRemark"
          ></el-input>
        </el-form-item>
      </el-form>
       <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="submitRemark" type="primary"  >确 认</el-button>
        <el-button size="small" @click="remarkBool = false"  >取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="taskBool">
      <div style="width: 100%; height: 500px" id="taskMap"></div>
    </el-dialog>
  </div>
</template>

<script>
import {
  taskpage,
  updatetask,
  getNetwork,
  getUserList,
  planInfos,
  getRemark,
  redeploy,
  excelLineTask,
  getLength
} from "../../RequestPort/maintenance/task";
import { exportMethod } from '../../common/js/exportExcel'
import { queryFeatrue } from '../Gis/apis/geo';
import { formatLocation } from '../Gis/utils/geoTool';
export default {
  name: "inspeTask",
  data() {
    return {
      pickerOptions0: {
          disabledDate(time) {
            return time.getTime() >= Date.now() - 8.64e7;
          }
        }, 
        // 备注
        remarkData:{
          taskRemark:''
        }, 
        // 备注弹窗
        remarkBool:false,
        // 转派弹窗
        redeployVisible:false,
        //任务状态 0-未开始 1-进行中 2-部分完成3 已完成
        options: [{
          value: '0',
          label: '未开始'
        }, {
          value: '1',
          label: '进行中'
        }, 
         {
          value: '2',
          label: '已完成'
        },{
          value: '3',
          label: '未完成'
        }
        ,{
          value: '4',
          label: '已转派'
        }
        ],
       
        // 查询条件
      paramsData: {
        current: 1,
        size: 100,
        taskType:1,
        taskGenerateStatus:null,
      },
      rules: {
        taskName: [
          { required: true, message: "请输入任务名称", trigger: "blur" },
        ],
        planId: [{ required: true, message: "请选择巡检计划", trigger: "change" }],
        taskUser: [{ required: true, message: "请选择巡线员", trigger: "change" }],
        taskExecuteOnceDate: [{ required: true, message: "请选择任务时间", trigger: "change" }],
        taskFrequency: [
          { required: true, message: "请输入每天频率", trigger: "change" }
        ],
        executeDay: [{ required: true, message: "请选择天数", trigger: "change" }],
         taskDurationStartTime: [{ required: true, message: "请选开始日期", trigger: "change" }],
         taskRemark:[
          { required: true, message: "备注不能为空", trigger: "blur" },
        ]
      },
      rules1: {
         taskRemark:[
          { required: true, message: "备注不能为空", trigger: "blur" },
        ]
      },
      // 新增任务
      addVisible: false,
      // 总条数
      total: 0,
      // 查询暂存的时间段
      DataPicker: [],
      // 任务列表
      tableData: [],
      // 复选暂存
      multipleSelection: [],
      // 片区字典，巡检区域
      selectDownArray: [],
      // 巡线人员
      excutorDownArray: [],
      // 巡检计划列表
      patrolArray: [],
      // 新增任务对象
      DeviceData: {
        planDurationType: 0,
        planTaskConfigIds: [],
        taskType: "unone",
        // 时间范围
        StartAndEndTime:[],
        taskRemark:''
      },
      // 弹窗
      titles:'',
      // 巡线图片
      taskBool: false,
      // 转派人员下拉
      excutorSpecialArray:[],
      
    };
  },
  watch:{
  },
  mounted() {
    // 巡检区域字典，片区下拉
    getNetwork().then((e) => {
      this.selectDownArray = e.data;
    });
    // 获取巡线员
    getUserList({type:2}).then((e) => {
      this.excutorDownArray = e.data;
    });
    // 获取列表
    this.loadlist(this.paramsData);
   
    /**
     * 巡检计划
     */
    planInfos().then((e) => {
      this.patrolArray = e.data;
    });
  },
  methods: {
     seeKm(row){
      getLength({id:row.networkId}).then(res => {
        if(res.code == 200) {
          row.jsL = res.data
        }
      })
      // let coverage=row.coverage
      // let network=row.location
      // let pos = []
      // network.forEach(element => {
      //   pos.push(element[0] + ' ' + element[1])
      // })
      // pos.push(pos[0])
      // let posStr = pos.join(',')
      // let groupLayer = this.$store.getters.groupLayer
      // let group = []
      // if (groupLayer && groupLayer.length > 0) {
      //   groupLayer.forEach(item => {
      //     group.push(item.id)
      //   })
      // }

      
      
      // let layersVue1 = coverage
      // let params = {
      //   SERVICE: 'wfs',
      //   VERSION: '1.0.0',
      //   REQUEST: 'GetFeature',
      //   typeName: 'linqing:view_all_line_q',
      //   outputFormat: 'application/json',
      //   viewparams: `netid:${row.networkId}`,
      //    CQL_FILTER:
      //       "coverage in (" + layersVue1 + ") and INTERSECTS(location,POLYGON((" + posStr + ")))"
      //   }
      //   if (!layersVue1) {
      //    params.CQL_FILTER = '1=2'
      //   }  
      //    const loading = this.$loading({
      //     lock: true,
      //     text: 'Loading',
      //     spinner: 'el-icon-loading',
      //     background: 'rgba(0, 0, 0, 0.7)'
      //   });      
      // queryFeatrue(params).then(res => {//调取管线
      //    loading.close();
       
      //     //  this.total = res.totalFeatures
      //       let f = res.features
      //       let line=[]
      //         f.forEach(item => {
      //           let location=formatLocation(item.properties.location_str,'LineString')               
      //               for(let i=1;i<location.length;i++){                 
      //                 let sub=[Number(location[i-1][0]),Number(location[i-1][1])]
      //                 let sub1=[Number(location[i][0]),Number(location[i][1])]                
      //                 line.push([sub1,sub])
      //               }               
      //       })

      //         let py=network
      //       // py.push(py[0])//首尾相接
      //         let allLength=0
      //         line.forEach(item => {//计算长度
      //             var line1 = turf.lineString(item);
      //             var line2 = turf.lineString(py);
      //             var intersects = turf.lineIntersect(line1, line2);  
      //             if(intersects.features.length<1){//没相交是面内
      //                 let pn = turf.polygon([py]);                 
      //                 let isHave=turf.booleanContains(pn, line1);
      //                 if(isHave){                   
      //                     let length = turf.length(line1, {units: 'kilometers'});
      //                     allLength=allLength+length
      //                 }                
      //             }else if(intersects.features.length>0){//一条线相交了两次及以上
      //               var line = turf.lineString(item);
      //               var splitter = turf.lineString(py);
      //               var splitline = turf.lineSplit(line, splitter);     
      //               splitline.features.forEach(fea => {     
      //                 let feaLine=  fea.geometry.coordinates
      //                 let pn = turf.polygon([py]);
      //                 let pl = turf.lineString(feaLine);
      //                 let isHave=turf.booleanContains(pn, pl);
      //                 if(isHave){
      //                   let l=turf.lineString(feaLine)
      //                   let length = turf.length(l, {units: 'kilometers'});
      //                   allLength=allLength+length
      //                 }
      //               }); 
      //             }
      //         })
      
      //    allLength>0?row.jsL=allLength.toFixed(3):row.jsL='0'
          
      // })

    },
    SeetracFun(row){
      let id = row.trackIds
      //  this.$router.push({ path: '/home/device/pathDetails', query: { id: id,screcd:1 }})
      let chakan = this.$router.resolve({
					path: '/home/device/pathDetails',      //跳转目标窗口的地址
					query: { id: id,screcd:1 }
				})
				window.open(chakan.href);
    },
    exportDetil(){
       this.$message.success('正在下载中，完成后会有消息提醒，请稍等')
      let obj = {excelName: this.excelName,...this.search}
      excelLineTask(this.paramsData).then(res=>{
        exportMethod(res, '管线巡检任务')
      })
    },
    /**
     * 任务状态
     */
    taskStatus(start){
      if(start == '进行中') return 'warning' 
      if(start == '已转派') return '' 
      if(start == '已完成') return 'success' 
      if(start == '未完成') return 'danger' 
    },
    /**
     * 斑马线
     */
    tableRowClassName({row, rowIndex}) {
        if (rowIndex%2==0) {
          return 'warning-row';
        } else {
          return 'success-row';
        }
      },
      
    /**
     * 备注弹窗
     */
    IsOpenFun(e, row) {
      this.remarkBool = true
      if(this.$refs.remarkData){
        this.$refs.remarkData.resetFields()
      }
      this.remarkData.id = row.id
      this.remarkData.taskRemark = row.taskRemark
    },
    /**
     * 备注提交
     */
    submitRemark(){
      this.$refs.remarkData.validate((valid) => {
        if (valid) {
          getRemark(this.remarkData).then(res => {
            this.$message.success("添加备注成功");
            this.loadlist(this.paramsData);
            this.remarkBool = false
          })
        }
      })
    },
    
    /**
     * 编辑新增关闭事件
     */
    CloseChangePlan() {
      this.DeviceData = {
        planDurationType: 0,
        planTaskConfigIds: [],
        taskType: "unone",
        StartAndEndTime:[],
      };
      this.WeekSelectArray = [];
      this.MountSelectArray = [];
      this.$refs.DeviceData.resetFields()
    },
    /**
     * 新增任务
     */
    addGroup() {
      this.titles = '新增任务'
      this.addVisible = !this.addVisible;
    },
    /**
     * 转派
     */
    handle(row) {
      this.titles = '转派'
      this.DeviceData = {...this.DeviceData,...row}
      this.DeviceData.taskUser = '';
      // 获取巡线员
      getUserList({userId:row.taskUser,type:2}).then((e) => {
        this.excutorSpecialArray = e.data;
      });
      this.redeployVisible = !this.redeployVisible;
      if(this.$refs.DeviceData){
        this.$refs.DeviceData.resetFields()
      }
    },
    /**
     * 转派提交
     */
    submitRedeploy(){
        this.$refs.DeviceData.validate((valid) => {
        if (valid) {
          let data = {id:this.DeviceData.id,taskDurationEndTime:this.DeviceData.taskDurationEndTime,taskDurationStartTime:this.DeviceData.taskDurationStartTime,taskRemark:this.DeviceData.taskRemark,taskUser:this.DeviceData.taskUser}
          redeploy(data).then(res => {
            this.$message.success(res.msg);
            this.loadlist(this.paramsData);
            this.redeployVisible = false
          })
        }
      })
    },
    
    /**
     * 查询
     */
    search() {
      if (this.DataPicker&&this.DataPicker.length > 0) {
        this.paramsData.startTime = this.DataPicker[0];
        this.paramsData.endTime = this.DataPicker[1];
      }else{
        this.paramsData.startTime =null;
        this.paramsData.endTime = null;
      }
      this.paramsData.current = 1;
      this.loadlist(this.paramsData);
    },
    handleCurrentChange(val) {
      this.paramsData.current = val;
      this.loadlist(this.paramsData);
    },
    handleSizeChange(val) {
      this.paramsData.size = val;
      this.loadlist(this.paramsData);
    },

  
  /**
   * 重置
   */
    resize() {
      this.paramsData = {
        current: 1,
        size: 100,
        taskType:1,
        searchText: "",
      };
      this.DataPicker = [];
      this.loadlist(this.paramsData);
    },
    /**
     * 复选
     */
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    
    /**
     * 获取列表
     */
    loadlist(obj) {
      taskpage(obj).then((res) => {
        res.data.records.forEach(item => {
            item.jsL='-1'
          });
        this.tableData = res.data.records;
        // this.tableData = [{}]
        this.total = res.data.total;
      });
    },
  },
  
};
</script>

<style scoped lang="less">
.point {
  width: 100%;
  
}
.contain{
  background-color:transparent;
  padding: 0;
}
// 搜索栏样式
.modalityTop{
    // border: 1px solid #ecedf1;
    // padding: 20px 0;
    border-radius: 5px;
    background: #ffffff;
    margin-bottom: 12px;
  // 面包屑
  // .crumbs{
  //   padding: 10px 15px 20px;
  //   span{
  //     color: #3069E1;
  //   }
  // }
  // .el-form-item{
  //   margin-left: 20px;
  // }
  // .el-form-item__label{
  //   text-align: justify;
  // }
}
// 搜索
.searchForm{
  display: flex;
  flex-direction: row;
   flex-wrap: wrap;
    // justify-content: space-between;
  .el-form-item{
    margin: 0;
  }
}
// 表格样式
.modalityCenter{
  background: #ffffff;
  // padding: 20px;
  // 新增按钮
  .addButton{
    margin-bottom: 10px;
  }
//    ::v-deep.warning-row{
//     background: #F9FAFC;
//      .el-table__cell>.cell {
//     font-size: 14px;
//     color: #0C235B;
    
//      }
  }
// ::v-deep.success-row{ 
//   background: #ffffff;
//   .el-table__cell>.cell {
//     font-size: 14px;
//     color: #0C235B;
//   }
// }
// }
// ::v-deep.el-table th.el-table__cell>.cell{
//   font-size: 15px;
// }
  .el-pagination{
        margin: 15px 0 15px;
  }
  // ::v-deep.el-date-picker{
  //   top: 521px!important;
  //   left: 477px!important;
  // }
</style>
